import { graphql } from 'gatsby'
import React from 'react'
import GuideMarkdown from '../components/GuideMarkdown'
import Seo from '../components/Seo'
import { PAGE_VIEWED } from '../constants/metrics'
import BasicLayout from '../layouts/BasicLayout'
import MetricsService from '../services/metricsService'

interface IToSProps {
  data: {
    strapiTermsOfService: {
      content: any
    }
  }
}

export const query = graphql`
  query ToSQuery {
    strapiTermsOfService {
      content
    }
  }
`

const PageToS: React.FC<IToSProps> = ({ data }): JSX.Element => {
  const { content } = data.strapiTermsOfService
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: 'Terms of Service',
  })

  return (
    <BasicLayout>
      <Seo title={'Terms of Service'} />
      <article className="below-header-container">
        <div className="content">
          <GuideMarkdown content={content} />
        </div>
      </article>
    </BasicLayout>
  )
}

export default PageToS
